import React from 'react';
import classnames from 'classnames';
import Layout from '../../components/layout';
import Navbar from '../../components/navbar/navbar';
import MobileNavbar from '../../components/mobile-navbar/mobile-navbar';

import {
  ColoredSection,
  DisplayText,
  Header,
  HeroImage,
  LayoutUI,
  Stack,
  TextStyle
} from '../../ui-kit';
// import { dasherize } from '../../utils/string';

import HeaderImage from '../../assets/images/about/header.jpg';
import * as styles from './About.module.scss';

// Success icons
import InstagramIcon from '../../assets/images/about/icons/instagram.svg';
import TwitterIcon from '../../assets/images/about/icons/twitter.svg';
import FacebookIcon from '../../assets/images/about/icons/facebook.svg';

// Meet the team
import Randy from '../../assets/images/about/team/randy.jpg';
import Holly from '../../assets/images/about/team/holly.jpg';
import Stefie from '../../assets/images/about/team/stefie.jpg';
import Isabella from '../../assets/images/about/team/isabella.jpg';
import Yuki from '../../assets/images/about/team/yuki.jpg';
import Jonathan from '../../assets/images/about/team/jonathan.jpg';
import Julie from '../../assets/images/about/team/julie.jpg';
import Tra from '../../assets/images/about/team/tra.jpg';
import Sheena from '../../assets/images/about/team/sheena.jpg';

// Advisory counsel
import Dana from '../../assets/images/about/board/dana.jpg';
import Alex from '../../assets/images/about/counsel/alex.jpg';
import Ben from '../../assets/images/about/counsel/ben.jpg';
import Carole from '../../assets/images/about/counsel/carole.jpg';
import Chade from '../../assets/images/about/counsel/chade.jpg';
import Dacher from '../../assets/images/about/counsel/dacher.jpg';
import Esther from '../../assets/images/about/counsel/esther.jpg';
import Firdaus from '../../assets/images/about/counsel/firdaus.jpg';
import Karen from '../../assets/images/about/counsel/karen.jpg';
import Laura from '../../assets/images/about/counsel/laura.jpg';
import Michael from '../../assets/images/about/counsel/michael.jpg';
import Ralph from '../../assets/images/about/counsel/ralph.jpg';
import Ramsey from '../../assets/images/about/counsel/ramsey.jpg';

// Board of directors
import David from '../../assets/images/about/board/david.jpg';
import Melissa from '../../assets/images/about/board/melissa.jpg';

// Happiness coalition
import Charter from '../../assets/images/about/coalition/charter.jpg';
import Edu from '../../assets/images/about/coalition/edu.jpg';
import GreaterGood from '../../assets/images/about/coalition/greater-good.jpg';
import Guyana from '../../assets/images/about/coalition/guyana.jpg';
import HappierTV from '../../assets/images/about/coalition/happier-tv.jpg';
import KeyClub from '../../assets/images/about/coalition/key-club.jpg';
import Kind from '../../assets/images/about/coalition/kind.jpg';
import NativeCry from '../../assets/images/about/coalition/native-cry.jpg';
import Rethink from '../../assets/images/about/coalition/rethink.jpg';
import Ross from '../../assets/images/about/coalition/ross.jpg';
import Saklan from '../../assets/images/about/coalition/saklan.jpg';
import Stanford from '../../assets/images/about/coalition/stanford.jpg';
import TinyBuddha from '../../assets/images/about/coalition/tiny-buddha.jpg';
import Trinidad from '../../assets/images/about/coalition/trinidad.jpg';
import Tsu from '../../assets/images/about/coalition/tsu.jpg';
import Usc from '../../assets/images/about/coalition/usc.jpg';
import He from '../../assets/images/about/coalition/hector-estrada.jpg';
import Joga from '../../assets/images/about/coalition/joga-paddles.jpg';

const SUCCESS_ICONS = [
  {
    icon: FacebookIcon,
    alt: 'Facebook Icon',
    number: '2.5m',
    suffix: 'Likes'
  },
  {
    icon: InstagramIcon,
    alt: 'Instagram Icon',
    number: '157k',
    suffix: 'Followers'
  },
  {
    icon: TwitterIcon,
    alt: 'Twitter Icon',
    number: '18k',
    suffix: 'Followers'
  }
];

const TEAM_MEMBERS = [
  {
    image: Randy,
    name: 'Randy Taran',
    title: 'Founder & CEO',
    favoriteQuote:
      '“Thousands of candles can be lighted from a single candle, and the life of the candle will not be shortened. Happiness never decreases by being shared.” – Buddha',
    bio: [
      'Randy is the founder and CEO of Project Happiness. When her own child was suffering from stress and depression, this ignited a passion to share the best resources to help others facing similar challenges. She is the co-author of the Project Happiness Handbook, bringing the best of positive psychology, neuroscience and mindfulness to youth. This book has become the backbone for our leading social and emotional learning curriculum, serving students, teachers, counselors and coaches in every state and over 120 countries.',
      'Randy produced the award-winning documentary “Project Happiness” which brought together young people from three continents to ask one question – “What is the nature of lasting happiness?” They interviewed George Lucas, Richard Gere, neuroscientist Richard Davidson and ultimately, in Dharamsala, India, had an unforgettable private audience with the Dalai Lama.',
      'Through Project Happiness, Randy’s dream of empowering people with the resources to create greater happiness within themselves and the world is coming to life. With a following of over 2.5 million people on social media alone, messages of hope and science-based strategies for greater well-being are reaching people when they need it most. In these stressful times, enhancing and tapping into our inner resources is more important than ever.',
      'Randy, who is thrilled to be doing this work, speaks regularly on well-being and living your best life, areas she covers on her regular blogs in the Huffington Post, and in weekly videos on Facebook Live. She lives near Palo Alto, California with her family and well-loved dogs. Randy is a Yoga Alliance certified teacher who holds her MBA in International Business and Marketing from New York University. She knows deeply that greater happiness is possible and everything you need is inside of you.'
    ],
    instagram: 'https://www.instagram.com/randytaran/',
    facebook: 'https://www.facebook.com/RandyKTaran/',
    twitter: 'https://twitter.com/RandyTaran',
    linkedin: 'https://www.linkedin.com/in/randy-taran-64aba14/'
  },
  {
    image: Holly,
    name: 'Holly DeYoung',
    title: 'Director of Programs & Marketing',
    favoriteQuote:
      '"Don\'t ask what the world needs. Ask what makes you come alive, and go do it. Because what the world needs is people who have come alive.” – Howard Thurman',
    bio: [
      `As Director of Programs, Holly DeYoung oversees creation, execution, evaluation, and maintenance all of Project Happiness’ programs being rolled out, both in the United States and abroad. She is also involved in strategic partnerships, including those for funding and development, and is working to streamline Project Happiness' technology, platforms and systems.`,
      'Holly brings to Project Happiness her 10 plus years of experience in international development and management. Over this time, Holly has been responsible for overseeing the building and operations of two health clinics in rural Uganda, is on the International Board of Directors for MCE Uganda, an up-and-coming Ugandan NGO dealing with underemployed youth populations, and has founded an HIV/AIDS support organization in a Ugandan slum.',
      'Prior to pursuing her Masters Degree in International Development and Public Health, Holly was the Director of Client Services at an email based Customer Relationship Management software, helping colleges and universities build and strengthen relationships through a powerful email database.'
    ],
    linkedin: 'https://www.linkedin.com/in/holly-deyoung-7679b55/'
  },
  {
    image: Stefie,
    name: 'Stefie Gan',
    title: 'Social Media Designer',
    favoriteQuote:
      '“Passion is energy. Feel the power that comes from focusing on what excites you.” — Oprah Winfrey',
    bio: [
      'As the Social Media Designer, Stefie Gan creates daily inspiration and teaches happiness tools through art and writing. She serves the 2.4 million worldwide community on Facebook, Instagram, Twitter, and Email Listserv. She has featured some of her original illustrations, and the community has reacted well to them!',
      'Stefie graduated from Barnard College, Columbia University with a degree in Architecture, but she really “majored in unafraid,” a saying coined by fellow alumnae Anna Quindlen ‘74. Unafraid to explore stories that are meaningful to her, she embarked on a journey to develop her craft in writing and fine art. Stefie took a myriad of self-enrichment classes in watercolor, animation, figure drawing, and animal drawing, just to name a few. She believes in life-long learning and it’s never too late to stir your life in a different direction. Her current interests lie in calligraphy and landscape painting. It has been quite an adventure so far, where she has had the opportunity to create animations and art for her Alma Mater, Scholastic, Oscar Nominee Bill Plympton, a Curious George Documentary, Moneyish, sibling of the Wall Street Journal, and now Project Happiness!',
      'A native New Yorker currently basking in sunny California, Stefie spends her weekends hiking in national parks, playing tennis, skiing, teaching younger people how to write, and doing meditative painting and grinding of inks.'
    ],
    instagram: 'https://www.instagram.com/stefiegan/',
    twitter: 'https://twitter.com/stefiegan',
    website: 'https://stefiegan.com/'
  },
  {
    image: Isabella,
    name: 'Isabella Andersen',
    title: 'Office Manager',
    favoriteQuote:
      '“During times of universal deceit, telling the truth becomes a revolutionary act” – George Orwell',
    bio: [
      'As the Office Manager at Project Happiness, Isabella Andersen oversees day-to-day activities and uses her organisational skills to provide an efficient, and happy office environment.',
      'Born and bred Aussie, Isabella moved to the Bay Area in 2017 to experience what it’s like to work and live in the United States. Isabella was thrilled to be offered a job at Project Happiness, due to her love of working for non-profit organisations and her passion for human rights and wellness.',
      'Isabella holds a Bachelor’s Degree in International Studies and has interned and volunteered with a variety of organisations including: The Australian Human Rights Commission, Amnesty International, The Red Cross, and the International Detention Coalition. Working with like-minded people is something she values highly, especially those who enjoy having a laugh and discussing current world issues.',
      'You’ll find her on the weekend exploring different regions of California searching for the best brunch, local markets, op-shops, and scenery. Isabella balances her love for food with her (not quite so equal) love for running through the park near her house. She likes to relax by drawing, painting, reading and watching TV series.'
    ],
    linkedin: 'https://www.linkedin.com/in/isabella-andersen-6a524b123/'
  },
  {
    image: Julie,
    name: 'Julie Arnheim',
    title: 'Director of Development',
    favoriteQuote:
      '“For every minute you are angry you lose sixty seconds of happiness.” ― Ralph Waldo Emerson',
    bio: [
      'As Director of Development, Julie is responsible for fundraising and strategic partnerships with corporations, foundations, and individual donors.',
      'Julie brings to Project Happiness her 20+ years of experience in both the business and non-profit worlds. Starting out in product management and sales of financial software, Julie now applies her business and marketing skills to the non-profit arena. As fundraising chair for the Los Altos Educational Foundation, she was responsible for operating existing and new, innovative stewardship programs for donors, recruiting volunteers, and growing the donor base. As a Board member of the San Francisco Jewish Federation overseeing $1 billion in endowment assets, as well as chair of the Board’s Leadership Development Committee, she has been involved with grantmaking, capacity building, and developing programs to meaningfully engage lay leaders, as well as Board education.',
      "She graduated from the University of Illinois with a degree in Finance and attended University of Chicago's Graduate School of Business. In her spare time, she enjoys traveling to new destinations, practicing yoga, reading voraciously, and spending time outdoors with her family. She is enormously appreciative of all the philanthropic generosity making it possible for the Project Happiness team to provide preventive wellness programs to schools, people and communities."
    ]
  },
  {
    image: Jonathan,
    name: 'Jonathan Stone',
    title: 'Lead Software Developer',
    favoriteQuote:
      "“The great thing about giving up is that it's not permanent.” – Liz Powell",
    bio: [
      'As Lead Software Engineer, Jonathan Stone designs, architects and builds custom web and mobile applications. He is responsible for managing interns and contract software engineers who help to build our software.',
      "Jonathan has 19 years of experience testing and developing software. He started out building enterprise software and later branched off on his own to develop iPhone apps. His current specialties include full-stack web and iPhone development using Node, Express, React, MongoDB, Postgres and Swift. He holds a Bachelor's degree in Biology.",
      'Passionate about spreading the word that life gets better when you change your attitude, he enjoys learning about tools that decrease suffering and increase life satisfaction. When he’s not writing code, you can find Jonathan at the ice rink playing hockey, reading, or doing something active.'
    ]
  },
  {
    image: Yuki,
    name: 'Yuki Dorff',
    title: 'Graphic Designer',
    favoriteQuote:
      '"Feel free to pick strawberries in the summer and make snowmen in the winter."',
    bio: [
      'Born in Japan, Yuki Dorff studied graphic design at Brigham Young University. She is a graphic designer working in branding, web design, and user experience. She is responsible for the user experience and visual design of Project Happiness. Working hand in hand with a developer and copywriter, she aims to create delightful interactions and beautiful designs.',
      'She enjoys bike rides, laying on the ground and looking up at trees, eating chicken wings and well-dressed men. She also loves laughing.'
    ],
    instagram: 'https://www.instagram.com/yukidorff',
    linkedin: 'https://www.linkedin.com/in/yuki-dorff-41030967/',
    website: 'http://www.yukidorff.com'
  },
  {
    image: Tra,
    name: 'Tra Hitt',
    title: 'Web Developer, Programs',
    favoriteQuote:
      '“Ultimately, your Path to Happiness is Your Path alone. The trick is to identify others whose paths run parallel to yours and walk them together.”',
    bio: [
      "Tra Hitt has been part of the Project Happiness Team since November 2014 as principal web-designer and all around nerd. Tra took a newly minted Bachelor's Degree in Psychology and thought what better way to try it out than to join the U.S. Navy. Having figured out that his degree had very little to do with being an Aviation Anti-Submarine Warfare Operator, Aircrew member and Rescue Swimmer, he thought he would see if the it would work better in the US Coast Guard. It did. He found out that boats did what he wanted them to do if he would just reason with them and reflect their feelings. Soon after being overheard using his “outside” voice in telling the boat how it was going to navigate a particularly nasty Lake Erie storm, Tra left the Coast Guard to concentrate on being a husband, new father and all around nice guy.",
      'A lot happened between then and now. Two children successfully raised to become their own tax deductions, the sudden loss of his first wife after almost 22 years of marriage, and the ability to “see” life as the precious commodity it is. Tra ducked out of the corporate world with most of his sanity intact and embarked on a journey of helping others tell their story to the world with his years of training and experience in all things computers, marketing and design, which he gratefully does with Project Happiness now.',
      'When not actively working with the Project Happiness Team to help bring Happiness to the world, Tra can either be found at home helping others tell their story or sunset bound with a camera in hand. Trä, Rebecca, their 4 1/2 kids and petting zoo worth of animals call the West Coast of Florida their home base now, but don’t be surprised if they show up anywhere in the world, because traveling and experiencing other cultures brings them...well...Happiness!'
    ]
  },
  {
    image: Sheena,
    name: 'Sheena Brown',
    title: 'Research Consultant',
    favoriteQuote:
      '“Happiness is the meaning and the purpose of life, the whole aim and end of human existence.” – Aristotle',
    bio: [
      'Dr. Sheena Brown is an adjunct lecturer of Neuroanatomy at the University of Arizona. From 2003 until 2013, she worked as Staff Scientist in the Neuroscience Department at the University of Arizona. Dr. Brown also teaches anatomy and physiology to health pre-majors at a community college, and recently introduced the course The Art & Science of Happiness to UA freshmen. To continue her professional development and increased involvement in community health and wellbeing, Sheena is currently completing a Masters in Public Health.',
      'Her interests are focused on the emotional well being of youth, and in using mindfulness-based curricula to provide young people with tangible skills to face the stress and challenges in their lives, to reduce the externalization of stress through risk behaviors such as substance use, unsafe sex, anxiety, depression and suicide.',
      'Sheena graduated from the University of Otago, New Zealand in 2000 with a Ph.D in Zoology and Anatomy. After graduating, she worked as assistant lecturer in the Zoology Department, teaching comparative physiology and neurobiology. Following a short stint at Dalhousie University in Halifax Nova Scotia, Sheena moved to Tucson Arizona in 2003.',
      'Sheena is actively involved in her local sangha and regularly attends mindfulness trainings and retreats, and is currently working towards MBSR facilitator certification. She enjoys volunteering at a local middle school teaching mindfulness meditation and has started up a meditation group at UA, with materials funded by the Pollination Project. In her spare time, Sheena enjoys hanging out with her husband Francis, her English bulldog Mabel, and her cat Flossie.'
    ]
  }
];

const BOARD_OF_DIRECTORS = [
  {
    image: David,
    name: 'David Taran',
    favoriteQuote:
      '“Happiness is not something you postpone for the future; it is something you design for the present.” – Jim Rohn',
    bio: [
      'David Taran is a longtime member of the Project Happiness board, focused on providing strategic and long-term growth ideas for the organization.',
      'Apart from his commitment to help the happiness movement grow, David is the founder and Managing Director of Sunstar Capital, a real estate company focused on the acquisition of West Coast properties. David Taran has spent decades working in the real estate industry, where he has assembled a diverse portfolio of properties throughout the U.S. In his previous role, he cofounded and was co-CEO of Divco West Properties. Prior to finding success in the real estate market, David worked as an attorney at Graham & James in Los Angeles. David Taran has published numerous articles on real estate and tax law.',
      `A lifelong advocate of the power of an education, David credits his studies as one of the defining moments of his young life. As a student, David Taran pursued his DEC from McGill University. He went on to receive his JD from Columbia University in New York. He continues to support the overarching goal of Project Happiness: to inspire others to adopt positive strategies to better their lives. While happiness cannot be bought, it’s power can be adopted through patience and practice. David Taran's first hand knowledge of the organization’s profound effect on positive thinking continues to inspire his own education and life goals.`,
      `In recent years, David, a long time meditator, has found a new passion in yoga, and has completed teacher trainings in Hatha, Kundalini, Buddhi and Universal yoga. One of the driving forces in David Taran's life is his commitment to balancing his personal and professional ventures. To David, leading a successful life encompasses so much more than following an ambitious career trajectory. In order to cultivate a true and sustained passion for living, he continues to carve out time each day to focus on the everyday moment. David’s passion for finding joy amid life's challenging obstacles uniquely qualifies him to serve as a seasoned board member for Project Happiness. Catch David Taran on his personal blog where he discovers and shares even more ways to be happy.`
    ],
    twitter: 'https://twitter.com/DavidTaran',
    youtube: 'https://www.youtube.com/channel/UCy3tKkUvUYu7u6_WLVgGKTg',
    linkedin: 'https://www.linkedin.com/in/davidtaran/',
    website: 'http://davidtaran.net/'
  },
  {
    // Because it exists elsewhere, Randy's info doesn't need to be filled in
    image: Randy,
    name: 'Randy Taran',
    title: 'Chairman',
    bio: []
  },
  {
    image: Melissa,
    name: 'Melissa Hollatz',
    favoriteQuote: '“Happiness is a warm puppy.” - Charles M. Schulz',
    bio: [
      'Melissa Hollatz is a partner at Wilson Sonsini Goodrich & Rosati, where she has practiced corporate and securities law since graduating from Stanford Law School. She focuses on public and private company corporate representation and corporate governance, with an emphasis on technology companies.',
      'Melissa has extensive transactional experience, including mergers and acquisitions, public and private securities offerings, tender and exchange offers, venture capital financings, and other strategic transactions. Because of her passion for helping non-profits, she co-chairs the firm’s pro bono committee.',
      'Melissa is particularly thrilled to be on the board of Project Happiness. She has represented Project Happiness as pro bono legal counsel for nearly a decade. She is excited about helping focus on strategic matters in addition to legal guidance.',
      'Melissa has wonderful family and friends. She is passionate about riding her horses, spoiling her border collie and tabby cats, and patting any animal that will stand still long enough.  '
    ],
    linkedin: 'https://www.linkedin.com/in/melissa-hollatz-5b3b9a3/'
  }
];

const ADVISORY_COUNSEL = [
  {
    image: Dana,
    name: 'Dana Cappiello'
  },
  {
    image: Ralph,
    name: 'Ralph Wolff'
  },
  {
    image: Dacher,
    name: 'Dacher Keltner'
  },
  {
    image: Carole,
    name: 'Carole Pertofsky'
  },
  {
    image: Esther,
    name: 'Esther Wojcicki'
  },
  {
    image: Ben,
    name: 'Dr. Tal Ben-Shahar'
  },
  {
    image: Laura,
    name: 'Dr. Laura Delizonna'
  },
  {
    image: Chade,
    name: 'Meng Chade-Tan'
  },
  {
    image: Alex,
    name: 'Alex Gersznowicz'
  },
  {
    image: Michael,
    name: 'Michael Eldredge'
  },
  {
    image: Firdaus,
    name: 'Dr. Firdaus Dhabhar'
  },
  {
    image: Ramsey,
    name: 'Dr. Ramsey Khasho'
  },
  {
    image: Karen,
    name: 'Dr. Karen Webb'
  }
];

const HAPPINESS_COALITION = [
  {
    image: Charter,
    name: 'Charter for Compassion',
    url: 'https://www.charterforcompassion.org'
  },
  {
    image: Edu,
    name: 'Edutopia',
    url: 'https://www.edutopia.org'
  },
  {
    image: GreaterGood,
    name: 'Greater Good Science Center',
    url: 'http://greatergood.berkeley.edu'
  },
  {
    image: Guyana,
    name: 'Guyana',
    url: 'https://education.gov.gy/web/'
  },
  {
    image: HappierTV,
    name: 'Happier.tv',
    url: 'http://www.happier.tv'
  },
  {
    image: KeyClub,
    name: 'Key Club',
    url: 'http://keyclub.org/home.aspx'
  },
  {
    image: Kind,
    name: 'KIND Snacks',
    url: 'http://www.kindsnacks.com'
  },
  {
    image: NativeCry,
    name: 'Native Cry',
    url: 'http://www.nativecry.org'
  },
  {
    image: Rethink,
    name: 'ReThink',
    url: 'http://www.rethink.co.tt'
  },
  {
    image: Ross,
    name: 'Ross',
    url: 'http://rossadvertisingcaribbean.com'
  },
  {
    image: Saklan,
    name: 'The Saklan School',
    url: 'http://www.saklan.org'
  },
  {
    image: Stanford,
    name: 'Stanford University',
    url: 'http://ccare.stanford.edu'
  },
  {
    image: TinyBuddha,
    name: 'Tiny Buddha',
    url: 'http://tinybuddha.com'
  },
  {
    image: Trinidad,
    name: 'Trinidad and Tobago',
    url: 'http://www.news.gov.tt'
  },
  {
    image: Tsu,
    name: 'Tennessee State University',
    url: 'http://www.tnstate.edu'
  },
  {
    image: Usc,
    name: 'University of Southern California',
    url: 'http://www.usc.edu'
  },
  {
    image: He,
    name: 'Hector Estrada',
    url: 'http://hectorestrada.com'
  },
  {
    image: Joga,
    name: 'Joga Paddles',
    url: 'https://www.jogapaddles.com/'
  }
];

function renderSuccessIcons() {
  return SUCCESS_ICONS.map(({ icon, alt, number, suffix }) => (
    <Stack spacing="tight" key={alt}>
      <img src={icon} alt={alt} className={styles.SuccessIcon} />
      <Header>
        {number}
        <span className={styles.SuccessIconSuffix}>{suffix}</span>
      </Header>
    </Stack>
  ));
}

function renderProfileImages(profileImages, showName = true) {
  return profileImages.map(({ image, name, title, bio, url }) => {
    const innerMarkup = (
      <Stack vertical spacing="tight">
        <img src={image} alt={name} />
        <TextStyle color="gray-darker">
          {showName && <p className={styles.TeamMemberName}>{name}</p>}
          {title && (
            <p>
              <TextStyle color="gray-dark">{title}</TextStyle>
            </p>
          )}
        </TextStyle>
      </Stack>
    );

    let outerMarkup;
    if (bio != null) {
      outerMarkup = <a href={url}>{innerMarkup}</a>;
    } else if (url != null) {
      outerMarkup = (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={url} target="_blank">
          {innerMarkup}
        </a>
      );
    } else {
      outerMarkup = innerMarkup;
    }

    return <div key={`${name}`}>{outerMarkup}</div>;
  });
}

const About = () => (
  <Layout>
    <Navbar color="white" />
    <MobileNavbar color="white" />
    <div>
      <HeroImage size="large" image={HeaderImage} overlay="dark">
        <LayoutUI>
          <Stack alignment="center" distribution="center">
            <TextStyle center color="white">
              <DisplayText>OUR MISSION</DisplayText>
              <Header size="small">
                We are dedicated to empowering people with the resources to
                create greater happiness within themselves and the world.
              </Header>
            </TextStyle>
          </Stack>
        </LayoutUI>
      </HeroImage>

      <div className={styles.SuccessIcons}>
        <ColoredSection color="gray-lighter" height="small">
          <Stack distribution="center" spacing="extraLoose" alignment="center">
            {renderSuccessIcons()}
          </Stack>
        </ColoredSection>
      </div>

      <LayoutUI>
        <TextStyle center color="purple-light">
          <Header size="small">Meet the Team</Header>
        </TextStyle>
        <div className={classnames(styles.FlexContainer, styles.ThreeUp)}>
          {renderProfileImages(TEAM_MEMBERS)}
        </div>
      </LayoutUI>

      <LayoutUI>
        <TextStyle center color="blue">
          <Header size="small">Board of Directors</Header>
        </TextStyle>
        <div className={classnames(styles.FlexContainer, styles.ThreeUp)}>
          {renderProfileImages(BOARD_OF_DIRECTORS)}
        </div>
      </LayoutUI>

      <LayoutUI>
        <TextStyle center color="teal">
          <Header size="small">Advisory Counsel</Header>
        </TextStyle>
        <div className={classnames(styles.FlexContainer, styles.FourUp)}>
          {renderProfileImages(ADVISORY_COUNSEL)}
        </div>
      </LayoutUI>

      <LayoutUI>
        <Stack vertical spacing="extraLoose">
          <TextStyle center color="orange">
            <Header size="small">Happiness Coalition</Header>
          </TextStyle>
          <div className={classnames(styles.FlexContainer, styles.FiveUp)}>
            {renderProfileImages(HAPPINESS_COALITION, false)}
          </div>
        </Stack>
      </LayoutUI>
    </div>
  </Layout>
);

export default About;
